:root {
  --primary: #0f327e;
  --primary-20: rgba(15, 50, 126, 0.2);
  --Secondary-1: #f4f5f9;
  --Secondary-2: #c0c3cd;
  --Gray-1: #666;
  --Gray-2: #999;
  --Gray-3: #dcdcdc;
  --Background: #fff;
  --On-Primary: #fff;
  --on-primary-20: #333;
  --On-Secondary-1: #333;
  --On-Secondary-2: #333;
  --On-Background-1: #333;
  --On-Background-2: #fff;
}
