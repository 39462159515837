@font-face {
  font-family: 'Pretendard';
  font-style: thin;
  font-weight: 100;
  src: url('./../assets/fonts/Pretendard-Thin.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: extraLight;
  font-weight: 200;
  src: url('./../assets/fonts/Pretendard-ExtraLight.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: light;
  font-weight: 300;
  src: url('./../assets/fonts/Pretendard-Light.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: regular;
  font-weight: 400;
  src: url('./../assets/fonts/Pretendard-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: medium;
  font-weight: 500;
  src: url('./../assets/fonts/Pretendard-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: semiBold;
  font-weight: 600;
  src: url('./../assets/fonts/Pretendard-SemiBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: bold;
  font-weight: 700;
  src: url('./../assets/fonts/Pretendard-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: extraBold;
  font-weight: 800;
  src: url('./../assets/fonts/Pretendard-ExtraBold.woff2') format('woff2');
}

@font-face {
  font-family: 'Pretendard';
  font-style: black;
  font-weight: 900;
  src: url('./../assets/fonts/Pretendard-Black.woff2') format('woff2');
}
