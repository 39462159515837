.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 9999;
}

.ReactModal__Overlay > div {
  outline: none;
}
