* {
  box-sizing: border-box;
  font-family: 'Pretendard' !important;
}

button {
  cursor: pointer;
  border: none;
  background-color: unset;
}

input,
textarea {
  outline: none;
  border: none;
}

a {
  text-decoration: none;
  color: unset;
  cursor: pointer;
}

svg {
  display: block;
  cursor: pointer;
}
